<template>
    <div class="not-paid-page system-message">
        <div class="content">
            <div class="icon not-paid">
                <img src="/build/images/warning.svg">
            </div>
            <div class="description">
                {{ $t('not-module.description') }}
            </div>
            <div class="action-button">
                <button @click="openModalSubscription">
                    <span class="text">{{ ['not_instance', 'new'].includes($auth.user().rights.instance) ? $t('not-paid-first.button') : $t('not-paid.button')}}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
            }
        },
        methods: {
            openModalSubscription(){
                if( this.$auth.user().instance.global_stripe === 1 ){
                    this.$root.$emit('open_modal', 'change_subscription');
                }else{
                    window.location.replace(`${process.env.VUE_APP_CENTRAL_FE_LINK}/price`);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.icon {
    margin-top: 112px;
    margin-bottom: 18px;
    &.not-paid{
        img{
            height: 105px;
        }
    }
}
    
</style>